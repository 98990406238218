import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
import { IonicTimepickerModule } from '@logisticinfotech/ionic-timepicker';

import { MatExpansionModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatCheckboxModule, MatSortModule, MatFormFieldModule, MatSelectModule,
  MatAutocompleteModule, MatIconModule, MatButtonModule, MatRippleModule, MatBadgeModule, MatBottomSheetModule, MatButtonToggleModule, MatCardModule, MatDatepickerModule, MatChipsModule, MatDialogModule, MatDividerModule, MatInputModule, MatListModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatSidenavModule, MatSlideToggleModule, MatSliderModule, MatSnackBarModule, MatStepperModule, MatToolbarModule, MatTabsModule, MatTooltipModule, MatTreeModule
} from '@angular/material';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgxTinymceModule } from 'ngx-tinymce';

import { HighlightDirective } from '../directives/directive.directive';
import { CommonHeaderComponent } from '../my-component/common-header/common-header.component';
import { CommonFooterComponent } from '../my-component/common-footer/common-footer.component';
import { FiltermultiPipe } from '../pipe/filter.pipe';
import { RoundPipe } from '../pipe/roundof.pipe ';
import { DragDropDirective } from '../directives/file-upload.directive';
import { FileSizePipeConvertPipe } from '../pipe/fileSize-convert.pipe ';
import { SafeUrlPipe } from '../pipe/safeUrl.pipe ';
import { MyTooltipDirective } from '../directives/my-tooltip.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { AutofocusDirective } from '../directives/input-auto-focus.directive';
import { NgInitDirective } from '../directives/init.directive';
import { MustMatchDirective } from '../directives/must-match.directive';

// //owl date time picker
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgOtpInputModule } from 'ng-otp-input';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';


/* tslint:disable */ 
@NgModule({
  declarations: [
    HighlightDirective, //directive share
    CommonHeaderComponent, //header component share
    CommonFooterComponent,  //footer component share
    FiltermultiPipe, // custom filter multiple value
    DragDropDirective, //file upload directive
    FileSizePipeConvertPipe, //file size convert pipe/filter
    MyTooltipDirective, //not ues any where
    OnlyNumberDirective, // take only number
    AutofocusDirective,
    RoundPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    NgInitDirective,
    MustMatchDirective //match password or confirm password or email or confirm passwor
  ],
  imports: [
    CommonModule,
    IonicModule, //need for component share only
    // Material items start
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    // Material items end
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(), //not need any where
    Ionic4DatepickerModule,
    IonicTimepickerModule,
    NgSelectModule, //angular dropdown
    NgxTinymceModule.forRoot({
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/',
    }),
    OwlDateTimeModule,  //owl date time picker
    OwlNativeDateTimeModule, //owl date time picker
    NgOtpInputModule
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB'},
  ],
  exports:[
    HighlightDirective, 
    // Material items start
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    // Material items end
    CommonHeaderComponent, //header component share
    CommonFooterComponent,  //footer component share
    ReactiveFormsModule,
    FormsModule,
    NgMultiSelectDropDownModule, //not need any where
    Ionic4DatepickerModule,
    IonicTimepickerModule,
    FiltermultiPipe, // custom filter multiple value
    NgSelectModule, //angular dropdown
    DragDropDirective, //file upload directive
    FileSizePipeConvertPipe, //file size convert pipe/filter
    OnlyNumberDirective, // take only number
    AutofocusDirective,
    RoundPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    NgInitDirective,
    NgxTinymceModule,
    MustMatchDirective, //match password or confirm password or email or confirm passwor
    OwlDateTimeModule,  //owl date time picker
    OwlNativeDateTimeModule, //owl date time picker
    NgOtpInputModule
  ]
})
export class SharedModule { }
