import { Component, OnInit, OnDestroy } from '@angular/core';
import { Storage } from '@ionic/storage';
import { MenuController, Platform, AlertController, ModalController } from '@ionic/angular';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthLoginService } from '../../services/auth/auth.service';
import { AddCommonModelPage } from '../../pages/modal/add-common-model/add-common-model.page';
import { AppComponent } from '../../app.component';

/* tslint:disable */ 
@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
})
export class CommonHeaderComponent implements OnInit, OnDestroy {

  get_user_dtls;
  main_url = environment.apiUrl;
  file_url = environment.fileUrl;
  form_api;
  getlogoLoadData;
  getlogo_Url;
  getlogoReturnData:any;
  getprofileLoadData;
  getProfileData;
  getprofile_Url;

  private logoutDataSubscribe: Subscription;

  constructor( 
    private authService: AuthLoginService,
    private menuCtrl: MenuController,
    private http : HttpClient,
    private router: Router,
    private modalController : ModalController,
    private platform : Platform,
    private storage: Storage,
    private alertController : AlertController,
    private appComponent : AppComponent
    ) { }

  ngOnInit() {
    this.profile_logo();
    this.authService.globalparamsData.subscribe(res => {
      if(res != null || res != undefined){
        this.get_user_dtls = res.user;
      }
    });
  }

  ionViewWillEnter(){
    this.form_api = 'logout'; //logout api call
    this.authService.globalparamsData.subscribe(res => {
      if(res != null || res != undefined){
        this.get_user_dtls = res.user;
      }
    });
  }
  profile_logo(){
    this.getprofileLoadData = true;
    this.getprofile_Url = `userinfo`;
    this.http.get(this.getprofile_Url).subscribe(
      (res: any) => {
        this.getProfileData = res.return_data.userinfo;
        this.getprofileLoadData = false;
      },
      (err: any) => {
        this.getprofileLoadData = false;
      }
    );
  }

  menuEnable = true;
  toggleMenu() {
    this.menuEnable =! this.menuEnable;
    console.log('click menu button');
    this.menuCtrl.enable(this.menuEnable);
  }

  
  //---------getlogo----------
  getlogo(){
    this.getlogoLoadData = true;
    this.getlogo_Url = `site-info`;
    this.http.get(this.getlogo_Url).subscribe(
      (res: any) => {
        this.getlogoReturnData = res.return_data;
        this.getlogoLoadData = false;
      },
      (err: any) => {
        this.getlogoLoadData = false;
      }
    );
  }
  //---------getlogo----------

  

  // goProfilePageUrl
  goProfilePageUrl(){
    this.router.navigateByUrl(`add-user/edit/0`);
  }

  //----get storage user data start---------
    get_global_params = this.authService.getTokenSessionMaster();
  // get storage user data start end


  // ..... change Password modal start ......
    async changePasswordOpenModal(_identifier, _item, _items) {
      let change_password_modal;
      if(_identifier == 'change_password_modal'){
        change_password_modal = await this.modalController.create({
          component: AddCommonModelPage,
          cssClass: 'mymodalClass',
          componentProps: { 
            identifier: _identifier,
            modalForm_item: _item,
            modalForm_array: _items
          }
        });
      }
      
      // modal data back to Component
      change_password_modal.onDidDismiss()
      .then((getdata) => {
        if(getdata.data == 'submitClose'){
        }
      });

      return await change_password_modal.present();
    }
  // change Password   modal end 

  logOutUser(){
    this.onLogout();
  }
  //logout function end-
  
  // onLogout
  loadingShow = false;
  onLogout(){
    this.loadingShow = true;
    //edit data call
    this.logoutDataSubscribe = this.http.get('logout').subscribe(
      (res:any) => {
        this.loadingShow = false;
        if(res.return_status > 0){
          this.authService.logout();
        }
      },
      errRes => {
        this.loadingShow = false;
      }
    );
  }

  // ----------- destroy subscription start ---------
  ngOnDestroy() {
    if(this.logoutDataSubscribe !== undefined){
      this.logoutDataSubscribe.unsubscribe();
    }
  }
  // --------------destroy subscription end---------------
}
