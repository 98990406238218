import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

/* tslint:disable */ 
const routes: Routes = [
  { path: '', 
    redirectTo: 'home', 
    pathMatch: 'full' 
  }, // ================= Auth start ===================================
  { 
    path: 'auth',
    loadChildren: './pages/auth/auth.module#AuthPageModule',
  },
  { 
    path: 'forgot-password',
    loadChildren: './pages/auth/forgot-password/forgot-password.module#ForgotPasswordPageModule'
  },
  { 
    path: 'dashboard', 
    loadChildren: './pages/dashboard/dashboard.module#DashboardPageModule',
    canLoad: [AuthGuard]  
  }, 
  
  //===== error page ======
  { 
    path: 'error', 
    loadChildren: './pages/error/error.module#ErrorPageModule' 
  },
  //=================  assessment management start ================
  // list
  { 
    path: 'user', 
    loadChildren: './pages/user_management/user-list/user-list.module#UserListPageModule',
    canLoad: [AuthGuard]  
  },
  { 
    path: 'role', 
    loadChildren: './pages/user_management/role-list/role-list.module#RoleListPageModule',
    canLoad: [AuthGuard]  
  },
  { 
    path: 'contact', 
    loadChildren: './pages/user_management/contact-list/contact-list.module#ContactListPageModule'
  },
  // add edit
  { 
    path: 'add-user/:action/:id', 
    loadChildren: './pages/user_management/add-user/add-user.module#AddUserPageModule',
    canLoad: [AuthGuard]  
  },
  { 
    path: 'add-role/:action/:id', 
    loadChildren: './pages/user_management/add-role/add-role.module#AddRolePageModule',
    canLoad: [AuthGuard] 
  },

// -- assessment management end --
// =================== Student Management start ===================

  // Student List
  { 
    path: 'student',
    loadChildren: './pages/student_management/student-list/student-list.module#StudentListPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'add-student/:action/:id',
    loadChildren: './pages/student_management/add-student/add-student.module#AddStudentPageModule',
    canLoad: [AuthGuard]
  },
  { 
    path: 'profile-view/:id',
    loadChildren: './pages/student_management/profile-view/profile-view.module#ProfileViewPageModule',
    canLoad: [AuthGuard]
  },
  
  
// --- Student management end
// =================== Order Management start ===================
  // Order List
  { 
    path: 'order', 
    loadChildren: './pages/order_management/order-list/order-list.module#OrderListPageModule',
    canLoad: [AuthGuard] 
  },

  // =================== Pages for HM Shopping start =================
  { 
    path: 'register', 
    loadChildren: './pages/auth/register/register.module#RegisterPageModule',

  },
  { 
    path: 'welcome', 
    loadChildren: './pages/welcome/welcome.module#WelcomePageModule',
    canLoad: [AuthGuard]
  },
  // ----------Product Management start--------------
  { path: 'menu-list', 
    loadChildren: './pages/product_management/menu-list/menu-list.module#MenuListPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'submenu-list', 
    loadChildren: './pages/product_management/submenu-list/submenu-list.module#SubmenuListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'product-list', 
    loadChildren: './pages/product_management/product-list/product-list.module#ProductListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'add-product/:action/:id', 
    loadChildren: './pages/product_management/add-product/add-product.module#AddProductPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'add-submenu/:action/:id', 
    loadChildren: './pages/product_management/add-submenu/add-submenu.module#AddSubmenuPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'add-menu/:action/:id', 
    loadChildren: './pages/product_management/add-menu/add-menu.module#AddMenuPageModule' ,
    canLoad: [AuthGuard] 
  },
  // ----------Product Management end--------------
  // ----------Order Management start--------------
  { 
    path: 'recent-order-list', 
    loadChildren: './pages/order_management/recent-order-list/recent-order-list.module#RecentOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'pending-order-list/:id', 
    loadChildren: './pages/order_management/pending-order-list/pending-order-list.module#PendingOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'invoice-generated-list/:id',
    loadChildren: './pages/order_management/invoice-generated-list/invoice-generated-list.module#InvoiceGeneratedListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'packed-order-list/:id',
    loadChildren: './pages/order_management/packed-order-list/packed-order-list.module#PackedOrderListPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'shipped-order-list/:id',
    loadChildren: './pages/order_management/shipped-order-list/shipped-order-list.module#ShippedOrderListPageModule',
    canLoad: [AuthGuard] 
  },
  { path: 'delivered-order-list/:id',
    loadChildren: './pages/order_management/delivered-order-list/delivered-order-list.module#DeliveredOrderListPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'returned-order-list/:id', 
    loadChildren: './pages/order_management/return-order-list/return-order-list.module#ReturnOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'replaced-order-list/:id', 
    loadChildren: './pages/order_management/replaced-order-list/replaced-order-list.module#ReplacedOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { path: 'complete-order-list', 
    loadChildren: './pages/order_management/complete-order-list/complete-order-list.module#CompleteOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'cancelled-order-list/:id', 
    loadChildren: './pages/order_management/cancel-order-list/cancel-order-list.module#CancelOrderListPageModule' ,
    canLoad: [AuthGuard] 
  },
  { 
    path: 'refunded-order-list/:id',
    loadChildren: './pages/order_management/refunded-order-list/refunded-order-list.module#RefundedOrderListPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'home', 
    loadChildren: './pages/home/home.module#HomePageModule' 
  },
  { 
    path: 'contact-us', 
    loadChildren: './pages/contact/contact.module#ContactPageModule' 
  },
  { 
    path: 'product-list/productfeatures/:id', 
    loadChildren: './pages/product_management/productfeatures-list/productfeatures-list.module#ProductfeaturesListPageModule' ,
    canLoad: [AuthGuard] 
  },
  
  { 
    path: 'product-features', 
    loadChildren: './pages/product-features/product-features.module#ProductFeaturesPageModule',
    canLoad: [AuthGuard] 
  },
  { 
    path: 'incart', 
    loadChildren: './pages/report/incart/incart.module#IncartPageModule' ,
    canLoad: [AuthGuard] 
  },
  {
    path: 'order-management',
    loadChildren: './pages/order-management/order-management.module#OrderManagementPageModule',
    canLoad: [AuthGuard]
   },
  { 
    path: 'account-management',
    loadChildren: './pages/account-management/account-management.module#AccountManagementPageModule',
    canLoad: [AuthGuard]
  },  { path: 'privacy-policy', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'terms-condition', loadChildren: './pages/terms-condition/terms-condition.module#TermsConditionPageModule' },



 
  
  
  
  // ----------Order Management end--------------
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
// RouterModule.forRoot(routes, { useHash: true })