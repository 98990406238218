// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiMaster:'1',
  apiKey:'qwertyuiop',
  
  // apiUrl:'http://192.168.1.17/hmshopping/hmshopping/api',
  // fileUrl:'http://192.168.1.17/hmshopping/hmshopping/'

  // apiUrl:'https://1211-223-191-8-91.in.ngrok.io/hmshopping/api',
  // fileUrl:'https://1211-223-191-8-91.in.ngrok.io/hmshopping/'

  // apiUrl:'http://192.168.1.96/hmshopping_project/hmshopping/api',
  // fileUrl:'http://192.168.1.96/hmshopping_project/hmshopping/'

  // apiUrl:'https://7440-2401-4900-1c01-712e-c507-fb76-e5eb-5947.in.ngrok.io/laravel_project/hmshopping/api',
  // fileUrl:'https://7440-2401-4900-1c01-712e-c507-fb76-e5eb-5947.in.ngrok.io/laravel_project/hmshopping/'
  
  // apiUrl:'http://localhost/hmshopping/api',
  // fileUrl:'http://localhost/hmshopping/public'  
  
  // apiUrl:'http://localhost/hmshopping/api',
  // fileUrl:'http://localhost/hmshopping/'

  apiUrl:'https://hmshopping.in/superadmin/api',
  fileUrl:'https://hmshopping.in/superadmin/'

  // apiUrl:'http://192.168.1.65/hmshopping_backend/api',
  // fileUrl:'http://192.168.1.65/hmshopping_backend/'

  // apiUrl:'https://bce8-171-79-9-159.in.ngrok.io/hmshopping/api',
  // fileUrl:'https://bce8-171-79-9-159.in.ngrok.io/hmshopping/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
